













































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import WarningIcon from '@/app/ui/assets/icon_warning_circle.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import { LocalStorage, Utils } from '@/app/infrastructures/misc'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'

interface PeriodRule {
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

@Component({
  components: {
    TextInput,
    Button,
    WarningIcon,
    Loading,
  },
})
export default class FundsWithdrawal extends Vue {
  controller = controller
  currentPeriod = ''

  history: Record<string, string | Date> = {
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
  }

  cutOffMechanismList = [
    {
      title: 'Bonus Performance',
      desc: 'Perubahan akan berfungsi di awal bulan',
    },
    { title: 'Basic FEE', desc: 'Perubahan akan berfungsi di hari esoknya' },
    {
      title: 'Penarikan Dana',
      desc: 'Perubahan akan berfungsi secara langsung',
    },
  ]

  created(): void {
    this.currentPeriod = LocalStorage.getLocalStorage('selectedPeriod')
    this.fetchData()
  }

  private fetchData(): void {
    controller.getDetail({
      id: this.$route.params.periodId,
      page: parseInt(<string>this.$route.params.page),
    })
  }

  private formatDateTime(dateTime?: string): string {
    if (!dateTime) return ''
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(dateTime, 'DD MMM YYYY, HH:mm Z')
    )
  }

  @Watch('controller.dataPeriodRule')
  setDataPeriodRule(data: PeriodRule): void {
    this.history = {
      createdBy: data.createdBy || '',
      createdAt: this.formatDateTime(data.createdAt) || '',
      updatedBy: data.updatedBy || '',
      updatedAt: this.formatDateTime(data.updatedAt) || '',
    }
  }
}
